"use client"

import React, { useState } from "react"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { useTranslations } from "next-intl"

import { cn } from "@/lib/utils"
import { Label, Switch } from "@/components/ui/components"
import StoryblokCustomerTypeDialog from "@/components/storyblok/header/StoryblokCustomerTypeDialog"

export default function StoryblokPrivateCustomerToggle({ userData }) {
  const [dialogVisible, setDialogVisible] = useState<boolean>(false)
  const { isB2BCustomer } = useUbStorefrontState()
  const t = useTranslations("storefront")

  // This is now done in UBHeaderClient:
  // useLayoutEffect(() => {
  //   const b2bCustomerCookieValue = Cookies.get(UB_CUSTOMER_TYPE_COOKIE_NAME)
  //   setIsB2BCustomer(CustomerTypeCookieValue.isB2bCustomer(b2bCustomerCookieValue))
  // }, [setIsB2BCustomer])

  function handleOnClose() {
    setDialogVisible(false)
  }

  return (
    <>
      {dialogVisible && <StoryblokCustomerTypeDialog onClose={handleOnClose} />}

      {!userData && (
        <div className="flex items-center space-x-2">
          <Label
            htmlFor="business-customer-mode"
            className={cn("tracking-tight xl:tracking-normal", {
              "font-bold": isB2BCustomer,
            })}
          >
            {t("customer.b2b")}
          </Label>
          <Switch
            aria-label="Privat oder Geschäft"
            id="business-customer-mode"
            checked={!isB2BCustomer}
            onCheckedChange={() => setDialogVisible(true)}
          />
          <Label
            htmlFor="business-customer-mode"
            className={cn("tracking-tight xl:tracking-normal", {
              "font-bold": !isB2BCustomer,
            })}
          >
            {t("customer.b2c")}
          </Label>
        </div>
      )}
    </>
  )
}
