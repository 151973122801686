import Link from "next/link"

import { cn } from "@/lib/utils"
import LucideIcon from "@/components/storyblok/common/LucideIcon"

import StoryblokLogoutButton from "./StoryblokLogoutButton"

export default function StoryblokAccountForm({ blok, userData }) {
  return (
    <ul role="list">
      <li className="text-lg font-bold">{userData?.lastName + ", " + userData?.firstName}</li>
      <li className="mb-2 border-b pb-2">{userData?.preferredInvoiceToAddress?.company}</li>
      {/*
      <p>ABC {JSON.stringify(userData)} ABC</p>
*/}
      {blok?.account_menu_links.map((item) => (
        <li
          className={cn("", {
            hidden:
              (!(userData?.type === "SMBCustomerUser") && item.hideB2C) ||
              (userData?.type === "SMBCustomerUser" && item.hideB2B),
          })}
          key={item.title}
        >
          <Link
            href={item.link.cached_url}
            className="group flex gap-x-3 rounded-md py-2 font-bold leading-6 hover:text-primary-hover"
          >
            <LucideIcon blok={item?.icon[0]} aria-hidden="true" />
            {item.title}
          </Link>
        </li>
      ))}
      <li>
        <StoryblokLogoutButton />
      </li>
    </ul>
  )
}
