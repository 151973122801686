export function getCategoryLink(category: string): string {
  return `${baseUrl()}/${slugifyUB(category)}/`
}

export function getBrandLink(brand: string): string {
  return `${baseUrl()}/brands/${slugifyUB(brand)}/`
}

export function getSearchLink(query: string): string {
  return `${baseUrl()}/search/${encodeURIComponent(query)}/`
}

export function baseUrl(): string {
  const baseUrl = process.env.NEXT_GENERATED_LINKS_BASE_URL ?? ""
  return baseUrl
}

//SEE: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
export function slugifyUB(input: string): string {
  if (input === null || input === undefined) {
    return ""
  }

  // lower case
  let result: string = input.toLowerCase()

  // now convert lowercase umlauts and szlig
  result = result
    .replace(/ä/g, "ae")
    .replace(/ö/g, "oe")
    .replace(/ü/g, "ue")
    .replace(/ß/g, "ss")
    // convert whitespaces and plus signs to minus
    .replace(/ /g, "-")
    .replace(/\+/g, "-")

  // strip accents
  result = result.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

  // replace everything that is not a letter or number
  result = result.replace(/[^a-zA-Z0-9\-]/g, "")

  // remove trailing dashes
  result = result.replace(/^\-+/, "")
  result = result.replace(/\-+$/, "")

  // replace multiple consecutive dashes with a single dash
  result = result.replace(/\-+/g, "-")

  return result
}

/* TODO - need to check if the above code is really correct
Legacy JAVA Code Found in UBRewriteHelper:

  public static String slugify(String input) {
    if (input == null) {
      return "";
    }
    // lower case
    String result = input.toLowerCase();

    // now convert lowercase umlauts and szlig
    result = result
        .replaceAll("\u00e4", "ae")
        .replaceAll("\u00f6", "oe")
        .replaceAll("\u00fc", "ue")
        .replaceAll("\u00df", "ss")
    // convert whitespaces and plus signs to minus
        .replaceAll(" ", "-")
        .replaceAll("\\+", "-");

    // replaces characters with accents used in French or Czech language
    result = StringUtils.stripAccents(result);

    // replace everything that is not a letter
    result = result.replaceAll("[^a-zA-Z0-9\\-]", "");

    // remove trailing dashes
    result = result.replaceAll("^[\\-]{1,}", "");
    result = result.replaceAll("[\\-]{1,}$", "");

    // replace multiple consecutive dashes with a single dash
    result = result.replaceAll("[\\-]{2,}", "-");
    return result;
  }

}
*/

export function appendQueryParameter(url: string, keyToAppend: string, valueToAppend: string): string {
  const dummyBase = "http://dummy"

  let urlObj
  if (url.startsWith("http://") || url.startsWith("https://")) {
    urlObj = new URL(url)
  } else {
    urlObj = new URL(url, dummyBase)
  }

  urlObj.searchParams.set(keyToAppend, valueToAppend)

  if (urlObj.origin === dummyBase) {
    return urlObj.pathname + urlObj.search
  }

  return urlObj.toString()
}
