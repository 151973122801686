"use client"

import { addArticleToCurrentBasketAction, determineCrosssellingProductsAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { ShoppingCart } from "lucide-react"
import { useTranslations } from "next-intl"

import { ProductData } from "@/lib/storefront/product/product"
import { determineCrosssellingProducts } from "@/lib/storefront/product/product-service"
import { Button } from "@/components/ui/components"

export default function ProductCardAddToBasket(props: {
  articleIdToAdd: string
  productData: ProductData | undefined
  prices: any
}) {
  const {
    setAddToBasketDialogVisible,
    setProductDataAddedToBasket,
    setBasketData,
    setCrosssellingProductDataAddedToBasket,
    setIsLoading,
  } = useUbStorefrontState()
  const t = useTranslations("storefront")

  async function addToBasket() {
    if (props.articleIdToAdd) {
      addArticleToCurrentBasketAction(props.articleIdToAdd, 1).then((basketData) => {
        if (basketData) {
          setBasketData(basketData)
        }
      })

      await determineCrosssellingProductsAction(props.articleIdToAdd).then((crosssellingProducts) => {
        setCrosssellingProductDataAddedToBasket(crosssellingProducts)
      })

      setCrosssellingProductDataAddedToBasket([])
    }
  }

  function addToBasketAndShowDialog() {
    setIsLoading(true)
    addToBasket().then(() => {
      setProductDataAddedToBasket(props.productData)
      setIsLoading(false)
      setAddToBasketDialogVisible(true)
    })
  }

  return (
    <Button
      aria-label={t("product.add_to_basket")}
      size={"productCardIcon"}
      onClick={(e) => {
        e.preventDefault()
        addToBasketAndShowDialog()
      }}
    >
      <ShoppingCart className={"size-4"} />
    </Button>
  )
}
