import { BasketData } from "@/lib/intershop/interfaces/basket/basket"
import { LineItemData } from "@/lib/intershop/interfaces/line-item/line-item"

export function findLineItemForSku(basket?: BasketData, sku?: string): LineItemData | null {
  if (basket?.data?.lineItems && sku) {
    for (let lineItemId of basket?.data?.lineItems) {
      if (basket?.included?.lineItems) {
        const lineItem = basket?.included?.lineItems[lineItemId]
        if (lineItem?.product === sku) {
          return lineItem
        }
      }
    }
  }
  return null
}
