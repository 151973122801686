"use client"

import { useLayoutEffect, useState } from "react"

import { getCountry, getLanguage } from "@/lib/link-utils/get-sb-locale"

function getTranslatedUri(uri: string, lang: string): string {
  // TODO: handle cases where storyname has different language names
  // -> Workaround until this is done: Always switch to start page.
  return "" // uri
}

function getSwitchedUri(uri: string): string {
  if (!window.location.pathname?.startsWith("/fr/CHF")) {
    // prepend "/fr/CHF" to switch language link if we are not already in fr-CH
    return "/fr/CHF" + getTranslatedUri(window.location.pathname, "fr")
  } else {
    //... otherwise remove /fr/CHF from path to switch to DE language in CH
    const newLink = window.location.pathname.split("/fr/CHF")[1]
    return getTranslatedUri(newLink, "de") || "/"
  }
}

export default function StoryblokSwissLocaleSwitch() {
  const [switchLanguageLink, setSwitchLanguageLink] = useState<string>("")

  useLayoutEffect(() => {
    setSwitchLanguageLink(getSwitchedUri(window.location.pathname))
  }, [])

  return (
    <>
      {getCountry() === "ch" && (
        <div className={"ml-8 flex justify-end"}>
          <a className={"flex items-center justify-between gap-1"} href={switchLanguageLink}>
            <div className="relative aspect-square h-5 rounded-sm bg-red-600">
              <span className="absolute inset-x-[40%] inset-y-[10%] bg-white"></span>
              <span className="absolute inset-x-[10%] inset-y-[40%] bg-white"></span>
            </div>
            <span>{getLanguage() === "de" ? "FR" : "DE"}</span>
          </a>
        </div>
      )}
    </>
  )
}
