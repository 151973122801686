//actions that can be called from client but run on server!
"use server"

import { extractAccessTokenFromApiTokenCookie, get } from "@/lib/intershop/api-client"
import { BasketData, BasketItems } from "@/lib/intershop/interfaces/basket/basket"
import {
  addSingleArticleToCurrentBasket,
  computeEcoTax,
  createBasket,
  fetchCurrentBasket,
  mergeBasket,
} from "@/lib/storefront/basket/basket-service"
import { ProductData } from "@/lib/storefront/product/product"
import { determineCrosssellingProducts, determineLinkedProducts } from "@/lib/storefront/product/product-service"
import { getSystemConfigValue } from "@/lib/system-config-loader"

export async function addArticleToCurrentBasketAction(
  article: string,
  quantity: number
): Promise<BasketData | undefined> {
  await addSingleArticleToCurrentBasket(article)
  const currentBasketData: BasketData | undefined = await fetchCurrentBasket()

  computeEcoTax(currentBasketData)

  return currentBasketData
}

export async function determineCrosssellingProductsAction(article: string): Promise<ProductData[]> {
  let result: ProductData[] = []

  const load_crossselling_products: string = getSystemConfigValue("load_crossselling_products")

  if (load_crossselling_products === "true") {
    const crosssellingProducts = await determineCrosssellingProducts(article)
    const linkedProducts = await determineLinkedProducts(article)
    result = linkedProducts.concat(crosssellingProducts)
  }

  return result
}

export async function getBasketItems(id: string): Promise<BasketItems | undefined> {
  return get<BasketItems>(`baskets/${id}/items`)
}

export async function mergeCurrentBasket(
  sourceBasketId: string | undefined,
  sourceApiToken: string | undefined
): Promise<BasketData | undefined> {
  const basket = (await fetchCurrentBasket()) || (await createBasket())

  let basketDataResult: BasketData | undefined

  if (basket) {
    if (sourceBasketId && sourceApiToken) {
      basketDataResult = await mergeBasket(sourceBasketId, sourceApiToken, basket.data.id)
    } else {
      basketDataResult = basket
    }
  }
  await computeEcoTax(basketDataResult)
  return basketDataResult
}

export async function extractApiTokenFromApiTokenCookie() {
  return extractAccessTokenFromApiTokenCookie()
}
