"use client"

import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { useTranslations } from "next-intl"

import { formatPrice } from "@/lib/helper"
import { getCountry, getSbLocale } from "@/lib/link-utils/get-sb-locale"
import { CardPrices, determineCardPrices } from "@/lib/price-utils"
import { ProductData } from "@/lib/storefront/product/product"

export default function ProductCardPrice(props: {
  productData: ProductData
  prices
  showEcoTaxFlag: boolean | undefined
}) {
  const { isB2BCustomer } = useUbStorefrontState()
  const t = useTranslations("storefront")

  const cardPrices: CardPrices = determineCardPrices(isB2BCustomer, props.prices)
  const ecoTax: number | undefined = props.productData?.ecoTax
  const ecoTaxFormatted = formatPrice(ecoTax, props.productData.currency)

  const country = getCountry()

  const showEcoTax = props.showEcoTaxFlag && country === "fr" && ecoTax && ecoTax > 0

  return (
    <div className={"flex flex-col items-end justify-start"}>
      <div className="flex flex-col items-end justify-end gap-0.5">
        {showEcoTax ? (
          <p className="text-gray-500 text-xs">{t("product.eco_tax", { ecoTax: ecoTaxFormatted })}</p>
        ) : (
          <span className="h-4" />
        )}
        <p className="text-gray-500 text-xs">{isB2BCustomer ? t("product.price_net") : t("product.price_gross")}</p>
      </div>
      <div className="flex flex-row items-baseline justify-end gap-1">
        {!cardPrices.isSinglePrice && <p className="text-gray-500 text-sm">{t("product.price_from")}</p>}
        <p className="text-xl font-bold text-primary">
          {cardPrices.salePriceFormatted}
          {isB2BCustomer ? "*" : ""}
        </p>
      </div>
      {cardPrices.listPriceFormatted !== cardPrices.salePriceFormatted ? (
        <div className="flex flex-row items-baseline justify-end gap-1">
          {!cardPrices.isSinglePrice && <p className="text-gray-500 text-sm">{t("product.price_from")}</p>}
          <p className="text-gray-500 text-sm font-bold line-through">{cardPrices.listPriceFormatted}</p>
        </div>
      ) : (
        <div className="flex flex-row items-baseline justify-end gap-1">
          <p className="text-sm font-bold text-transparent line-through">0</p>
        </div>
      )}
    </div>
  )
}
