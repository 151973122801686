import React from "react"

import { getCategoryLink } from "@/lib/link-utils/link-utils"
import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger } from "@/components/ui/components"
import { StoryblokCategoriesProps } from "@/components/storyblok/header/StoryblokCategories"

export default function StoryblokMobileCategories({ blok, categories }: StoryblokCategoriesProps) {
  return (
    <AccordionRoot type="single" collapsible>
      {categories.map((category) =>
        category.subCategories.length > 0 ? (
          <AccordionItem key={category.id} value={category.name}>
            <AccordionTrigger
              aria-label={"Categories"}
              className="bg-black font-semibold uppercase text-white hover:bg-black"
            >
              <a href={getCategoryLink(category.name)} className="flex h-full w-3/4 items-center text-left">
                {category.name}
              </a>
            </AccordionTrigger>
            <AccordionContent className="py-2 pl-2">
              <StoryblokMobileCategories blok={blok} categories={category.subCategories}></StoryblokMobileCategories>
            </AccordionContent>
          </AccordionItem>
        ) : (
          <a
            href={getCategoryLink(category.name)}
            className="mb-0.5 flex bg-black px-5 py-1.5  text-lg font-semibold text-white "
          >
            {category.name}
          </a>
        )
      )}
    </AccordionRoot>
  )
}
