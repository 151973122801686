"use client"

import React, { useMemo } from "react"
import Image from "next/image"
import Link from "next/link"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { XCircleIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { formatPrice } from "@/lib/helper"
import { getCountry } from "@/lib/link-utils/get-sb-locale"
import { findLineItemForSku } from "@/lib/storefront/basket/basketHelper"
import {
  Button,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/components"
import ProductCard from "@/components/product/ProductCard"

export default function AddToBasketDialogClient({ blok }) {
  const { addToBasketDialogVisible, setAddToBasketDialogVisible, isB2BCustomer } = useUbStorefrontState()
  const {
    productDataAddedToBasket,
    basketData,
    crosssellingProductDataAddedToBasket,
    setCrosssellingProductDataAddedToBasket,
  } = useUbStorefrontState()
  const t = useTranslations("storefront")

  const lineItem = useMemo(() => {
    return findLineItemForSku(basketData, productDataAddedToBasket?.id)
  }, [basketData, productDataAddedToBasket])

  const totalItemPrice = useMemo(() => {
    if (isB2BCustomer) {
      return formatPrice(lineItem?.pricing.price?.net.value, lineItem?.pricing.price?.net.currency)
    } else {
      return formatPrice(lineItem?.pricing.price?.gross.value, lineItem?.pricing.price?.gross.currency)
    }
  }, [isB2BCustomer, lineItem])

  const singlePriceFormatted = useMemo(() => {
    return formatPrice(productDataAddedToBasket?.price, productDataAddedToBasket?.currency)
  }, [productDataAddedToBasket])

  function closeDialog() {
    setAddToBasketDialogVisible(false)
    setCrosssellingProductDataAddedToBasket([])
  }

  const ecoTaxFormatted = useMemo(() => {
    return formatPrice(productDataAddedToBasket?.ecoTax, productDataAddedToBasket?.currency)
  }, [productDataAddedToBasket])

  const hasEcoTax = useMemo(() => {
    return (
      getCountry() === "fr" && productDataAddedToBasket?.ecoTax !== undefined && productDataAddedToBasket?.ecoTax > 0.0
    )
  }, [productDataAddedToBasket])

  const showHandlingFeeHint = useMemo(() => {
    return (
      getCountry() === "de" && productDataAddedToBasket?.price !== undefined && productDataAddedToBasket?.price < 50.0
    )
  }, [productDataAddedToBasket])

  return (
    <>
      {addToBasketDialogVisible && productDataAddedToBasket && (
        <>
          <div className={"fixed inset-0 z-50 bg-black/90"} onClick={() => closeDialog()}></div>
          <div
            className={
              "fixed left-1/2 top-1/3 z-[55] w-[95%] max-w-4xl -translate-x-1/2 -translate-y-1/3 bg-white md:w-4/5"
            }
          >
            <div className={"flex flex-col gap-4 p-2"}>
              <div className={"flex w-full justify-start gap-10 px-4"}>
                <div className={"absolute right-0 top-0"}>
                  <Button variant={"ghost"} onClick={closeDialog}>
                    <XCircleIcon className={"size-6"} />
                  </Button>
                </div>

                <h1 className={"mr-2 text-sm font-bold lg:text-2xl"}>{t("basket.product_added_successfully")}</h1>
              </div>

              <div className={"flex w-full flex-col justify-between gap-2 p-4 sm:flex-row"}>
                <div className={"relative flex w-full grow items-center justify-center gap-4 md:w-2/12"}>
                  <Image
                    unoptimized={true}
                    className="aspect-square max-h-40 overflow-hidden object-contain"
                    alt={productDataAddedToBasket.title}
                    src={productDataAddedToBasket.image ?? ""}
                    width={productDataAddedToBasket.image_width}
                    height={productDataAddedToBasket.image_height}
                  />
                  <div
                    className={
                      "center absolute -right-2 bottom-2 flex aspect-square h-4 items-center justify-center rounded-full border border-black bg-muted text-xs font-bold drop-shadow-md md:h-8 md:text-lg"
                    }
                  >
                    {lineItem?.quantity?.value ?? ""}
                  </div>
                </div>
                <div
                  className={
                    "flex w-full flex-col items-end justify-start overflow-hidden text-sm md:w-7/12 md:items-start"
                  }
                >
                  <h1 className={"text-sm font-bold md:text-lg"}>{productDataAddedToBasket.title}</h1>
                  <p className={"font-bold text-muted-foreground"}>
                    {t("product.article_number")}: {productDataAddedToBasket.id}
                  </p>
                  {/* <p>
                    {JSON.stringify(productDataAddedToBasket.variableVariationAttributes)}
                  </p>*/}
                </div>

                <div className={"flex w-full flex-col items-end justify-between md:w-3/12"}>
                  <div className={"flex w-full flex-col items-end justify-start gap-2"}>
                    <div className={"flex justify-between font-bold"}>
                      <p>{t("product.single_price")}:&nbsp;</p>

                      <p>{singlePriceFormatted}</p>
                    </div>

                    {hasEcoTax && (
                      <div className={"text-muted-background flex justify-between text-xs"}>
                        <p className={"text-nowrap"}>{t("header.shopping_basket.eco_tax")}:&nbsp;</p>
                        <p className="text-right">{<>{ecoTaxFormatted}</>}</p>
                      </div>
                    )}

                    <p className={"text-2xl font-bold"}>{totalItemPrice}</p>
                  </div>
                </div>
              </div>

              <div className={"flex w-full justify-end px-4"}>
                {hasEcoTax ? (
                  <p className={"w-auto text-xs text-muted-foreground sm:text-sm"}>
                    {t("product.tax_hint_eco", { ecoTax: ecoTaxFormatted })}
                  </p>
                ) : (
                  <>
                    {showHandlingFeeHint ? (
                      <p className={"w-auto text-xs text-muted-foreground sm:text-sm"}>
                        {t("product.handling_fee_hint")}
                      </p>
                    ) : (
                      <p className={"w-auto text-xs text-muted-foreground sm:text-sm"}>{t("product.tax_hint")}</p>
                    )}
                  </>
                )}
              </div>

              <div className={"flex w-full flex-col justify-between gap-2 p-4 sm:flex-row"}>
                <Button onClick={closeDialog} size={"lg"} variant="outline">
                  {t("basket.add_dialog.shopping")}
                </Button>

                <Button asChild size={"lg"}>
                  <Link prefetch={false} href={blok?.body[0]?.basket_url?.cached_url ?? "#"}>
                    {t("basket.add_dialog.basket")}
                  </Link>
                </Button>
              </div>
              {crosssellingProductDataAddedToBasket.length > 0 && (
                <div className={"hidden h-[30rem] w-full flex-row justify-center gap-2 p-1 sm:flex"}>
                  <Carousel
                    opts={{
                      align: "start",
                      loop: true,
                    }}
                    className="w-full px-4 @container/carousel"
                  >
                    <CarouselContent>
                      {crosssellingProductDataAddedToBasket?.slice(0, 8).map((product) => (
                        <CarouselItem className="@carousel:basis-1 flex justify-center @lg/carousel:basis-1/2 @xl/carousel:basis-1/3">
                          <div className="relative flex w-full max-w-[21.5rem] flex-col overflow-hidden rounded border-2 border-primary bg-white 3xl:max-w-[25rem]">
                            <ProductCard
                              compact={true}
                              showEcoTaxFlag={false}
                              productData={product}
                              showDescription={false}
                              standardVariant={undefined}
                              prices={{}}
                            />
                          </div>
                        </CarouselItem>
                      ))}
                    </CarouselContent>
                    <CarouselPrevious className={"border-2 border-primary bg-white text-primary"} />
                    <CarouselNext className={"border-2 border-primary bg-white text-primary"} />
                  </Carousel>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}
