import React, { type HTMLAttributes } from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva } from "class-variance-authority"

import { cn } from "../helpers/utils"
import {
  marginDef,
  textAlignDef,
  textColorDef,
  type MarginProps,
  type TextAlignProp,
  type TextColorProps,
} from "../props"

const headingVariants = cva("font-bold", {
  variants: {
    variant: {
      auto: "",
      h1: "text-4xl 3xl:text-5xl",
      h2: "text-3xl 3xl:text-[2.5rem]",
      h3: "text-2xl 3xl:text-[1.625rem]",
      h4: "text-xl 3xl:text-2xl",
      h5: "text-lg 3xl:text-xl",
      h6: "text-lg 3xl:text-xl",
    },
  },
  defaultVariants: {
    variant: "auto",
  },
})

type HeadingElement = React.ElementRef<"h1">

interface HeadingProps extends TextColorProps, TextAlignProp, MarginProps, HTMLAttributes<HTMLHeadingElement> {
  element?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
  textSize?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
  asChild?: boolean
}

const Heading = React.forwardRef<HeadingElement, HeadingProps>(
  (
    {
      children,
      element,
      marginTop,
      marginBottom,
      textColor,
      textAlign,
      textSize,
      className,
      asChild = false,
      ...restProps
    },
    forwardedRef
  ) => {
    const Element = element || "h1"

    return (
      <Slot
        {...restProps}
        ref={forwardedRef}
        className={cn(
          //The element h1,h2,h3... that is rendered
          headingVariants({ variant: element }),
          textColorDef({ textColor }),
          textAlignDef({ textAlign }),
          //The rendered "headline size"... useful for rendering headline elements that are semantically correct but need other text-sizes
          headingVariants({ variant: textSize }),
          marginDef({ marginBottom, marginTop }),
          className
        )}
      >
        {asChild ? children : <Element>{children}</Element>}
      </Slot>
    )
  }
)
Heading.displayName = "Heading"

export { Heading }
export type { HeadingProps }
