import Image, { ImageProps } from "next/image"
import Link from "next/link"

import { cn } from "@/lib/utils"
import StoryblokAssetClient from "@/components/storyblok/common/StoryblokAssetClient"

export default function HeaderLogo({ isCompact, logo, subtext }) {
  return (
    <Link prefetch={false} className="flex shrink-0 flex-col" rel="home" href={"/"} aria-label={"Start"}>
      <StoryblokAssetClient
        asset={logo}
        className={cn(
          "object-fit hidden h-[26px] w-[142px] transition-all duration-200 xs:block lg:h-[46px] lg:w-[256px]",
          {
            "min-h-[26px] max-h-[26px] lg:min-h-[46px] lg:max-h-[46px]": isCompact,
          }
        )}
        loading="eager"
        width={427}
        height={78}
      />

      <Image
        alt={"Start"}
        src={"/img/baer_transparent.png"}
        className={cn("object-fit block h-12 xs:hidden")}
        loading="eager"
        width={48}
        height={48}
      />

      {!isCompact && (
        <StoryblokAssetClient
          asset={subtext}
          className="mt-2 hidden h-[15px] w-[251px] lg:block"
          width={412}
          height={24}
        />
      )}
    </Link>
  )
}
