import { create } from "zustand"

import { BasketData } from "@/lib/intershop/interfaces/basket/basket"
import { UserData } from "@/lib/intershop/interfaces/user/user"
import { ProductData } from "@/lib/storefront/product/product"

interface UbStorefrontState {
  isB2BCustomer: boolean
  setIsB2BCustomer: (isB2BCustomer: boolean) => void
  addToBasketDialogVisible: boolean
  setAddToBasketDialogVisible: (visible: boolean) => void
  isLoading: boolean
  setIsLoading: (isLodging: boolean) => void
  userData?: UserData
  setUserData: (customerData: UserData | undefined) => void
  basketData?: BasketData
  setBasketData: (basket: BasketData) => void
  productDataAddedToBasket: ProductData | undefined
  setProductDataAddedToBasket: (productData: ProductData | undefined) => void

  crosssellingProductDataAddedToBasket: ProductData[]
  setCrosssellingProductDataAddedToBasket: (productData: ProductData[]) => void
}

export const useUbStorefrontState = create<UbStorefrontState>((set) => ({
  isB2BCustomer: true,
  // setIsB2BCustomer is called from UBHeaderClient to make is accessible from "everywhere".
  setIsB2BCustomer: (newIsB2BCustomer: boolean) => set(() => ({ isB2BCustomer: newIsB2BCustomer })),

  addToBasketDialogVisible: false,
  setAddToBasketDialogVisible: (visible: boolean) => set(() => ({ addToBasketDialogVisible: visible })),

  isLoading: false,
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),

  userData: undefined,
  setUserData: (userData: UserData) => set(() => ({ userData: userData })),

  basketData: undefined,
  setBasketData: (basketData: BasketData) => set(() => ({ basketData: basketData })),

  productDataAddedToBasket: undefined,
  setProductDataAddedToBasket: (productData: ProductData | undefined) =>
    set(() => ({ productDataAddedToBasket: productData })),

  crosssellingProductDataAddedToBasket: [],
  setCrosssellingProductDataAddedToBasket: (productData: ProductData[]) =>
    set(() => ({ crosssellingProductDataAddedToBasket: productData })),
}))
