import { FC } from "react"
import Image, { ImageProps } from "next/image"

import { AssetStoryblok } from "@/components/storyblok/component-types"

interface StoryblokImageProps extends Omit<ImageProps, "alt" | "src"> {
  asset?: AssetStoryblok
}

const StoryblokAssetClient: FC<StoryblokImageProps> = (props) => {
  if (!props.asset?.filename) return null

  const match = RegExp(/\/(\d+)x(\d+)\//).exec(props.asset.filename)
  let width = props.width
  let height = props.height

  if (!width || width == 0) {
    width = match ? Number(match[1]) : 0
  }
  if (!height || height == 0) {
    height = match ? Number(match[2]) : 0
  }

  // see also storyblok image service https://www.storyblok.com/docs/image-service/
  return (
    <>
      <Image
        loading={props.loading}
        src={props.asset.filename + `/m/${width}x${height}`}
        width={!props.fill ? width : undefined}
        height={!props.fill ? height : undefined}
        className={props.className}
        alt={props.asset.alt ?? ""}
        fill={props.fill}
      />
    </>
  )
}

export default StoryblokAssetClient
