import Link from "next/link"
import { CheckSquareIcon } from "lucide-react"

import { cn } from "@/lib/utils"

interface ValuePropositionProps {
  text: string
  link?: string
  className?: ValuePropositionProps | string
}

export default function StoryblokValueProposition(props: ValuePropositionProps) {
  return (
    <div className={cn("flex shrink-0 flex-row items-center", props.className)}>
      <CheckSquareIcon className={"min-h-5 text-yellow-500 xl:min-h-5"} />
      {props.link ? (
        <Link
          prefetch={false}
          className="text-gray-500 text-xs tracking-tight hover:text-black xl:text-sm"
          href={props.link}
        >
          {props.text}
        </Link>
      ) : (
        <p className="text-xs tracking-tight xl:text-sm">{props.text}</p>
      )}
    </div>
  )
}
