"use client"

import { useCallback, useEffect, useRef } from "react"
import { INTERSHOP_APITOKEN_COOKIE_NAME, UB_OPTANON_ALERT_BOX_CLOSED_COOKIE_NAME } from "@/constants"
import Cookies from "js-cookie"

import { setAnonymousApiTokenCookie } from "@/app/actions"

const DISABLE_WAIT_FOR_OPTANON = true

export default function AnonymousApiTokenClient() {
  const intervalId = useRef<undefined | ReturnType<typeof setInterval>>(undefined)

  const waitForOptanonAlertBoxClosed = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
    }
    intervalId.current = setInterval(() => {
      if (Cookies.get(UB_OPTANON_ALERT_BOX_CLOSED_COOKIE_NAME)) {
        setAnonymousApiTokenCookie().then((r) => {})
        clearInterval(intervalId.current)
      }
    }, 500)
  }, [])

  useEffect(() => {
    if (!Cookies.get(INTERSHOP_APITOKEN_COOKIE_NAME)) {
      if (DISABLE_WAIT_FOR_OPTANON) {
        setAnonymousApiTokenCookie().then((r) => {})
      } else {
        waitForOptanonAlertBoxClosed()
      }
    }
  }, [waitForOptanonAlertBoxClosed])

  return <></>
}
