import { cva, type VariantProps } from "class-variance-authority"

import { toClassVariant } from "../helpers/utils"

const textColorValues = ["primary", "secondary", "destructive", "muted", "accent", "gray", "black", "white", "current"]

const textColorDef = cva("", {
  variants: {
    textColor: toClassVariant(textColorValues, "text"),
  },
  defaultVariants: {
    textColor: "current",
  },
})

type TextColorProps = VariantProps<typeof textColorDef>

const bgColorValues = [
  "primary",
  "secondary",
  "destructive",
  "muted",
  "accent",
  "gray",
  "black",
  "white",
  "transparent",
]

const bgColorDef = cva("", {
  variants: {
    bgColor: toClassVariant(bgColorValues, "bg"),
  },
  defaultVariants: {
    bgColor: "current",
  },
})

type BackgroundColorProps = VariantProps<typeof bgColorDef>

export { textColorDef, textColorValues, bgColorDef, bgColorValues }
export type { BackgroundColorProps, TextColorProps }
