"use client"

import { useEffect, useLayoutEffect, useState } from "react"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import Cookies from "js-cookie"
import { AlignLeftIcon, SearchIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { CustomerTypeCookieValue, UB_CUSTOMER_TYPE_COOKIE_NAME } from "@/lib/customer-type-cookies"
import { BasketData } from "@/lib/intershop/interfaces/basket/basket"
import { UserData } from "@/lib/intershop/interfaces/user/user"
import { Category } from "@/lib/storefront/category/category"
import { cn } from "@/lib/utils"
import { UbHeaderStoryblok } from "@/components/storyblok/component-types"
import HeaderLogo from "@/components/storyblok/header/HeaderLogo"
import StoryblokAccountPopupClient from "@/components/storyblok/header/StoryblokAccountPopupClient"
import StoryblokMenuBar from "@/components/storyblok/header/StoryblokMenuBar"
import StoryblokPrivateCustomerToggle from "@/components/storyblok/header/StoryblokPrivateCustomerToggle"
import StoryblokSearchBox from "@/components/storyblok/header/StoryblokSearchBox"
import StoryblokServicePhone from "@/components/storyblok/header/StoryblokServicePhone"
import StoryblokShoppingBasketPopup from "@/components/storyblok/header/StoryblokShoppingBasketPopup"
import StoryblokSwissLocaleSwitch from "@/components/storyblok/header/StoryblokSwissLocaleSwitch"
import StoryblokValueProposition from "@/components/storyblok/header/StoryblokValueProposition"

interface UBHeaderProps {
  blok: UbHeaderStoryblok
  categories: Category[]
  userData?: UserData
  basket?: BasketData
  sbLocale: string
}

export default function UBHeaderClient({ blok, categories, userData, basket, sbLocale }: UBHeaderProps) {
  const [isCompact, setIsCompact] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const [prevScrollPos, setPrevScrollPos] = useState(0)

  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false)
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false)

  const { isB2BCustomer, setIsB2BCustomer, setBasketData } = useUbStorefrontState()

  const t = useTranslations("storefront")

  useLayoutEffect(() => {
    const b2bCustomerCookieValue = Cookies.get(UB_CUSTOMER_TYPE_COOKIE_NAME)
    const isB2bCookieSet = CustomerTypeCookieValue.isB2bCustomer(b2bCustomerCookieValue)

    const isEffectiveB2bCustomer = (userData && userData.customerType == "SMB") || (!userData && isB2bCookieSet)

    setIsB2BCustomer(isEffectiveB2bCustomer)
  }, [setIsB2BCustomer, userData, setBasketData])

  const STICKY_RANGE = 10

  useEffect(() => {
    setIsSticky(window.scrollY > STICKY_RANGE)

    if (basket) {
      setBasketData(basket)
    }
  }, [basket, setBasketData])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY
      setIsSticky(currentScrollPos > STICKY_RANGE)
      setIsCompact(currentScrollPos > prevScrollPos)
      setPrevScrollPos(currentScrollPos)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [prevScrollPos])

  const onMobileMenuClick = () => {
    const localIsMobileMenuVisible = !isMobileMenuVisible
    setIsMobileMenuVisible(localIsMobileMenuVisible)
    document.body.style.overflowY = localIsMobileMenuVisible ? "hidden" : "visible"
  }

  return (
    <div
      className={cn("fixed z-50 w-full bg-white", {
        "fixed z-50": isSticky,
        "h-full": isMobileMenuVisible,
      })}
    >
      <div
        className={cn(
          "border-gray-200 mx-auto flex h-14 max-w-7xl items-start border-b px-4 py-2 sm:px-6 lg:h-[90px] lg:border-0 lg:px-8",
          {
            "h-14 lg:h-[61px]": isCompact,
            "hidden lg:block": isMobileSearchVisible,
          }
        )}
      >
        <div className="flex w-full items-center gap-4 lg:items-start">
          <HeaderLogo isCompact={isCompact} logo={blok.logo} subtext={blok.subtext} />
          <div className={cn("flex grow flex-col justify-center gap-2", { "self-center gap-0": isCompact })}>
            <div
              className={cn("border-gray-200 hidden h-7 flex-row border-b transition-all lg:flex", {
                "h-0 opacity-0 pointer-events-none": isCompact,
              })}
            >
              <div className={"flex w-full flex-row items-center justify-between gap-1 pb-1"}>
                <div className="mr-2 flex grow flex-row items-center justify-between gap-2">
                  {isB2BCustomer ? (
                    <>
                      {blok.benefit_b2b_1 && (
                        <StoryblokValueProposition text={blok.benefit_b2b_1} link={blok.benefit_link_b2b_1?.url} />
                      )}
                      {blok.benefit_b2b_2 && (
                        <StoryblokValueProposition
                          text={blok.benefit_b2b_2}
                          link={blok.benefit_link_b2b_2?.url}
                          className={"hidden lg:flex"}
                        />
                      )}
                      {blok.benefit_b2b_3 && (
                        <StoryblokValueProposition
                          text={blok.benefit_b2b_3}
                          link={blok.benefit_link_b2b_3?.url}
                          className="hidden xl:flex"
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {blok.benefit_b2c_1 && (
                        <StoryblokValueProposition text={blok.benefit_b2c_1} link={blok.benefit_link_b2c_1?.url} />
                      )}
                      {blok.benefit_b2c_2 && (
                        <StoryblokValueProposition
                          text={blok.benefit_b2c_2}
                          link={blok.benefit_link_b2c_2?.url}
                          className={"hidden lg:flex"}
                        />
                      )}
                      {blok.benefit_b2c_3 && (
                        <StoryblokValueProposition
                          text={blok.benefit_b2c_3}
                          link={blok.benefit_link_b2c_3?.url}
                          className="hidden xl:flex"
                        />
                      )}
                    </>
                  )}
                </div>
                <div className={"flex items-center justify-between gap-1 lg:min-w-[26rem] xl:gap-2"}>
                  <StoryblokServicePhone blok={blok} sbLocale={sbLocale} />
                  <StoryblokPrivateCustomerToggle userData={userData} />
                </div>
              </div>
            </div>
            <div className="flex h-9 flex-row items-center justify-end gap-4">
              <div className="hidden size-full grow lg:block">
                <StoryblokSearchBox />
              </div>

              <button
                aria-label={t("header.search_placeholder")}
                onClick={() => setIsMobileSearchVisible(true)}
                className="text-gray-600 hover:bg-gray-100 flex h-full w-10 flex-row items-center justify-center gap-2 rounded px-2 font-semibold lg:hidden lg:w-96"
              >
                <SearchIcon className={"h-[20px] text-black"} />
              </button>
              <div className={"flex items-center justify-between gap-1 lg:min-w-[26rem] xl:gap-2"}>
                <StoryblokAccountPopupClient blok={blok} isCompact={isCompact} userData={userData} />
                <StoryblokShoppingBasketPopup blok={blok} isB2B={isB2BCustomer} userData={userData} />
                <StoryblokSwissLocaleSwitch />
              </div>
              <button
                onClick={onMobileMenuClick}
                className="text-gray-600 hover:bg-gray-100 flex h-full w-10 flex-row items-center justify-center gap-2 rounded px-2 font-semibold lg:hidden lg:w-96"
              >
                <AlignLeftIcon className={"h-[20px] text-black"} />
              </button>
            </div>
          </div>
        </div>
      </div>
      {isMobileSearchVisible && (
        <div className="p-3.5 lg:hidden">
          <StoryblokSearchBox onClose={() => setIsMobileSearchVisible(false)} />
        </div>
      )}

      <StoryblokMenuBar
        blok={blok}
        categories={categories}
        navigation={blok.navigation}
        isMobileVisible={isMobileMenuVisible}
        userData={userData}
      />
    </div>
  )
}
