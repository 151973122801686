// @ts-nocheck
// @ts-ignore
// ToDo: Fix typescript types!!!
"use client"

import { MouseEvent, useEffect, useRef, useState } from "react"
import Image from "next/image"
import { storyblokEditable } from "@storyblok/react/rsc"

import { ProductData } from "@/lib/storefront/product/product"
import { UbProductImageStoryblok } from "@/components/storyblok/component-types"

interface UBProductImageProps {
  blok: UbProductImageStoryblok
  product: ProductData
}

const UBProductImage = ({ blok, ...restProps }: UBProductImageProps) => {
  const product = restProps.product
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const image_box_ref = useRef(null)
  const zoom_box_ref = useRef(null)
  const zoom_view_ref = useRef(null)
  const [zoomBoxWidth, setZoomBoxWidth] = useState(0)
  const [zoomBoxHeight, setZoomBoxHeight] = useState(0)
  const [zoomBoxX, setZoomBoxX] = useState(0)
  const [zoomBoxY, setZoomBoxY] = useState(0)

  function onMouseMove(event: MouseEvent) {
    const image_box: HTMLImageElement | null = image_box_ref.current
      ? (image_box_ref.current as HTMLImageElement)
      : null
    const zoom_box: HTMLElement | null = zoom_box_ref.current ? (zoom_box_ref.current as HTMLElement) : null
    const zoom_view: HTMLImageElement | null = zoom_view_ref.current
      ? (zoom_view_ref.current as HTMLImageElement)
      : null

    if (zoom_box && image_box && zoom_view) {
      const bound = image_box.getBoundingClientRect()

      const maxX = bound.x + bound.width - zoom_box.clientWidth
      const maxY = bound.y + bound.height - zoom_box.clientHeight

      let newX = event.clientX - zoom_box.clientWidth / 2
      let newY = event.clientY - zoom_box.clientHeight / 2

      // Limit newX and newY to stay within bounds
      newX = Math.max(bound.x, Math.min(newX, maxX))
      newY = Math.max(bound.y, Math.min(newY, maxY))

      const width_ratio = product.zoom_image.width / bound.width
      const height_ratio = product.zoom_image.height / bound.height

      setZoomBoxX((newX - bound.x) * width_ratio * -1)
      setZoomBoxY((newY - bound.y) * height_ratio * -1)

      setMousePosition({
        x: newX,
        y: newY,
      })
    }
  }

  useEffect(() => {
    const image_box: HTMLImageElement | null = image_box_ref.current
      ? (image_box_ref.current as HTMLImageElement)
      : null
    const zoom_view: HTMLImageElement | null = zoom_view_ref.current
      ? (zoom_view_ref.current as HTMLImageElement)
      : null

    if (image_box && zoom_view) {
      const width_ratio = zoom_view.clientWidth / product.zoom_image.width
      const height_ratio = zoom_view.clientHeight / product.zoom_image.height

      setZoomBoxWidth(image_box.clientWidth * width_ratio)
      setZoomBoxHeight(image_box.clientHeight * height_ratio)
    }
  }, [product?.zoom_image.width, product?.zoom_image.height])

  return product ? (
    <div {...storyblokEditable(blok)} className="group relative flex">
      <div onMouseMove={onMouseMove} className="hover-initiator border-gray-400 size-96 rounded border p-2">
        <div className="flex h-full flex-col justify-center">
          <Image
            ref={image_box_ref}
            src={product.preview_image.url}
            alt={product.title}
            width={product.preview_image.width}
            height={product.preview_image.height}
            priority={true}
            className="w-full object-contain"
          />
        </div>
        <div
          ref={zoom_box_ref}
          style={{
            left: mousePosition.x,
            top: mousePosition.y,
            width: zoomBoxWidth,
            height: zoomBoxHeight,
          }}
          className="border-gray-400 fixed h-24 w-40 border bg-white opacity-0 hover:opacity-40"
        ></div>
      </div>
      <div
        ref={zoom_view_ref}
        style={{
          backgroundImage: `url(${product.zoom_image.url})`,
          backgroundPositionX: `${zoomBoxX}px`,
          backgroundPositionY: `${zoomBoxY}px`,
        }}
        className="hover-content border-gray-400 absolute ml-[25rem] h-96 w-[40rem] rounded border bg-red-300 object-contain"
      ></div>
    </div>
  ) : (
    <p>Product not found</p>
  )
}

export default UBProductImage
