export const Locales = {
  // name of the default locale configured in Storyblok
  defaultSbLocale: "",

  //  udobaer string locale(country - language) of the default Storyblok locale
  defaultUbLocaleForDefaultSbLocale: "de-de",

  isoLocalesForSbLocales: {
    "de-at": "de-AT",
    "de-ch": "de-CH",
    nl: "nl-NL",
    fr: "fr-FR",
    "fr-ch": "fr-CH",
    "": "de-DE",
  },

  intershopLocalesForSbLocales: {
    // Intershop Sites are configured to only use these languages (no country specifics):
    "de-at": "de_DE",
    "de-ch": "de_DE",
    nl: "nl_NL",
    fr: "fr_FR",
    "fr-ch": "fr_FR",
    "": "de_DE",
  },

  // this is the semantic of "default locale" in StoryBlok when the locale is empty ("")
  shortLocaleForDefaultSbLocale: "de",
}
