import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "../helpers/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-full text-sm 3xl:text-xl 3xl:font-bold font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground shadow group-hover:bg-primary/90 hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm group-hover:bg-destructive/90 hover:bg-destructive/90",
        outline:
          "border border-primary text-primary bg-background shadow-sm group-hover:bg-accent hover:bg-accent group-hover:text-accent-foreground hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground shadow-sm group-hover:bg-secondary/80 hover:bg-secondary/80",
        ghost: "group-hover:bg-accent hover:bg-accent group-hover:text-accent-foreground hover:text-accent-foreground",
        link: "text-primary underline-offset-4 group-hover:underline hover:underline",
      },
      size: {
        default: "h-9 px-4 py-2 3xl:h-[3.375rem] 3xl:px-10",
        sm: "h-8 rounded-full px-3 text-xs",
        lg: "h-10 rounded-full px-8",
        icon: "w-9 h-9 3xl:w-[3.375rem] 3xl:h-[3.375rem]",
        productCard: "h-9 px-4 py-2 3xl:text-base 3xl:font-normal",
        productCardIcon: "w-16 h-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return <Comp className={cn(buttonVariants({ variant, size }), className)} ref={ref} {...props} />
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
