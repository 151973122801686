"use client"

import { useState } from "react"
import Image from "next/image"
import { ChevronRightIcon } from "@heroicons/react/20/solid"
import { useTranslations } from "next-intl"

import { getCategoryLink } from "@/lib/link-utils/link-utils"
import { Category } from "@/lib/storefront/category/category"
import { UbHeaderStoryblok } from "@/components/storyblok/component-types"

export interface StoryblokCategoriesProps {
  blok: UbHeaderStoryblok
  categories: Category[]
}

export default function StoryblokCategories({ blok, categories }: StoryblokCategoriesProps) {
  const t = useTranslations("storefront")

  const MAX_NUMBER_SUBSUBCATEGORIES = 5

  const [selectedMainCategory, setSelectedMainCategory] = useState<Category | undefined>(undefined)

  const timeoutIds = {}

  const onMouseEnter = (category: Category) => {
    timeoutIds[category.id] = setTimeout(
      (category) => category.subCategories.length && setSelectedMainCategory(category),
      500,
      category
    )
  }

  const onMouseLeave = (category: Category) => {
    if (timeoutIds[category.id]) {
      clearTimeout(timeoutIds[category.id])
      timeoutIds[category.id] = undefined
    }
  }

  return (
    <div className="flex flex-row gap-x-10">
      {/* Main categories */}
      <div className="flex min-w-52 flex-col gap-4">
        {categories.map((mainCategory: Category) => (
          <div
            className="flex flex-row items-center gap-2"
            key={mainCategory.id}
            id={mainCategory.id}
            onMouseEnter={() => onMouseEnter(mainCategory)}
            onMouseLeave={() => onMouseLeave(mainCategory)}
          >
            {mainCategory.image_url && (
              <Image className="size-8" src={mainCategory.image_url} width={100} height={100} alt={mainCategory.name} />
            )}

            <a href={getCategoryLink(mainCategory.slug)} className="text-md flex-1 p-2 font-semibold">
              {mainCategory.name}
            </a>

            {!!mainCategory.subCategories.length && <ChevronRightIcon className="size-4 justify-items-end" />}
          </div>
        ))}
      </div>

      {/* Sub categories - conditionally visible dependant on main category */}
      {!!selectedMainCategory?.subCategories.length && (
        <div className="grid w-[50rem] grid-cols-3 gap-2 gap-x-10">
          {selectedMainCategory.subCategories.map((subCategory) => (
            <div key={subCategory.id} className="flex flex-col gap-0">
              <a href={getCategoryLink(subCategory.slug)} className="text-md border-b py-2 font-semibold">
                {subCategory.name}
              </a>

              <div className="pt-2">
                {subCategory.subCategories.slice(0, MAX_NUMBER_SUBSUBCATEGORIES).map((subsubCategory) => (
                  <div key={subsubCategory.id} className="">
                    <a href={getCategoryLink(subsubCategory.slug)} className="text-md flex-1 py-2 font-normal">
                      {subsubCategory.name}
                    </a>
                  </div>
                ))}

                {subCategory.subCategories.length > MAX_NUMBER_SUBSUBCATEGORIES && (
                  <div className="font-semibold">
                    <a href={getCategoryLink(subCategory.slug)} className="text-md flex-1 py-2 font-semibold">
                      {t("category.more")}
                    </a>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
