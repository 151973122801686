"use client"

import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { CardPrices, determineCardPrices } from "@/lib/price-utils"

export default function ProductCardDiscount(props: { prices }) {
  const { isB2BCustomer } = useUbStorefrontState()

  const cardPrices: CardPrices = determineCardPrices(isB2BCustomer, props.prices)
  return (
    <div className={"absolute left-4 top-4 size-16 rotate-[342deg] bg-transparent"}>
      {cardPrices && cardPrices.discountPercent > 0 ? (
        <div className={"flex size-full items-center justify-center rounded-full bg-primary text-primary-foreground"}>
          <p className={"text-lg font-bold"}>{cardPrices.discountPercentFormatted}</p>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}
